<template>
  <div class="wrap">
    <h1>Fragen & Antworten</h1>
    <div class="locations">
    <transition-group name="fade">
      <div class="fade">
        <h2 @click="toggle($event)">Wie ist der Dresscode?</h2>
        <p>Kleide dich festlich, aber so, dass du dich wohlfühlst!</p>
      </div>
      <div class="fade">
        <h2 @click="toggle($event)">Wo kann ich an der Kirche parken?</h2>
        <p>
          Die Trauung findet in der St. Nikolaus Kirche in Gerbrunn statt. Einige Parkplätze findet ihr im Schulweg oder im Allesgrundweg.
          Falls dort nichts mehr frei ist, könnt ihr auch am Sportplatz parken (ca. 10 Minuten zu Fuß).<br>
          Bitte plant genügend Zeit ein, um einen Parkplatz zu finden und rechtzeitig zur Kirche zu gelangen.<br>
          Wir empfehlen, mindestens 15 Minuten vor Beginn der Trauung vor Ort zu sein.
        </p>
      </div>
      <div class="fade">
        <h2 @click="toggle($event)">Wo kann ich an der Location parken?</h2>
        <p>
          Unsere Feier findet im „One-O-Two“ im Gewerbegebiet ConneKT in Kitzingen statt. Direkt an der Location befinden sich genügend Parkplätze, und ihr könnt eure Autos ohne Probleme bis Sonntagabend dort stehen lassen.
        </p>
      </div>
      <div class="fade">
        <h2 @click="toggle($event)">Wie steht ihr zu kreativen Beiträgen?</h2>
        <p>
          Vielen Dank für dein Interesse an kreativen Beiträgen!
          Wir möchten die Zeit mit euch verbringen und die Feier nicht mit Beiträgen unterbrechen. 
          Falls du etwas für den Hintergrund planen möchtest, wende dich bitte an unsere Trauzeugen.
        </p>
      </div>
      <div class="fade">
        <h2 @click="toggle($event)">Sind unsere Kinder auch eingeladen?</h2>
        <p>
          Ja, natürlich sind eure Kinder auch eingeladen! Gebt uns bitte Bescheid, 
          ob sie kommen werden. Es wird kein extra Kindermenü geben, aber wir lassen euch wissen, 
          was es zu essen gibt, damit ihr entscheiden könnt, ob eure Kinder das mögen. 
          Wir haben keine Kinderbetreuung organisiert, aber wir sorgen für ein paar Spielsachen und es gibt eine Grünfläche zum Toben.
        </p>
      </div>
      <div class="fade">
        <h2 @click="toggle($event)">Was kann ich euch schenken?</h2>
        <p>
          Vielen Dank, dass du an uns denkst! Wir freuen uns sehr über Geldgeschenke. Falls du lieber ein Sachgeschenk machen möchtest, wende dich bitte an unsere Trauzeugen, die dir gerne weiterhelfen.
        </p>
      </div>
    </transition-group>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TagesplanSite',
  components: {
  },
  props: {
    msg: String
  },
  created() {
     
  },
  mounted(){
    
  },  
  data() {
    return {
      showDiv1: true,
      showDiv2: true,
      showDiv3: true,
      showDiv4: true,
      showDiv5: true,
      showDiv6: true,
      showDiv7: true,
      showDiv8: true,
      showDiv9: true,
      showDiv10: true,
      showDiv11: true,
      showDiv12: true
    }
  },
  methods: { 
    toggle(event) {
      const content = event.target.nextElementSibling;
      if (content.style.display === "none" || !content.style.display) {
        content.style.display = "block";
      } else {
        content.style.display = "none";
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.wrap{
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 80vw;
}
p{
  font-family: "Poppins", serif;
  font-weight: 500;
  font-style: normal;
}
h1{
  font-size: 30pt;
}
.locations{
  margin-bottom: 50px;
}

.timeline{
  height: 50px;
  width: 4px;
  background-color: #3a6090;
  display: flex;
  justify-self: center;
}
.dot{
  width: 10px;
  height: 10px;
  margin-top: -5px;
  background-color: #3a6090;
  display: flex;
  justify-self: center;
  border-radius: 50%;
}
.fade {
  margin-bottom: 50px;
}
.fade h2{
  cursor: pointer;
}
.fade p {
  transition: max-height 0.5s ease-out;
  overflow: hidden;
  display: none;
}
</style>

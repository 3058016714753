<template>
  <div class="wrap">
    <h1>Tagesplan</h1>
    <div class="locations">
    <transition-group name="fade">
      <div v-show="showDiv1" class="timeline fade" ><div :style="trauung"></div></div>
      <div v-show="showDiv1" class="dot fade" :style="trauungdot"></div>
      <div v-show="showDiv2" class="fade">
        <h2>15:00 Uhr Trauung</h2>
        <p>
          Katholische Kirche St. Nikolaus Gerbrunn | Randersackererstraße | 97218 Gerbrunn <br> <br>
          Wir treffen uns alle schon 15 Minuten vorher an der Kirche, damit wir pünktlich starten können.</p>
      </div>
      <div v-show="showDiv3" class="timeline fade"><div :style="sekt"></div></div>
      <div v-show="showDiv3" class="dot fade" :style="sektdot"></div>
      <div v-show="showDiv4" class="fade">
        <h2>15:45 Uhr Sektempfang</h2>
        <p>Nach der Trauung stoßen wir mit Secco und Limonade gemeinsam an. Johanna ist euch sehr dankbar, dass ihr beim Gratulieren auf eine Umarmung mit ihr verzichtet.</p>
      </div>
      <div v-show="showDiv5" class="timeline fade"><div :style="apero"></div></div>
      <div v-show="showDiv5" class="dot fade" :style="aperodot"></div>
      <div v-show="showDiv6" class="fade">
        <h2>17:00 Uhr Apéro</h2>
        <p>One-O-Two | ConneKT 3 | 97318 Kitzingen<br><br>
          Bei einem schönen Glas Aperol Spritz oder Secco, genießen wir leckere Häppchen und starten gemütlich in den Abend.</p>
      </div>
      <div v-show="showDiv7" class="timeline fade"><div :style="essen"></div></div>
      <div v-show="showDiv7" class="dot fade" :style="essendot"></div>
      <div v-show="showDiv8" class="fade">
        <h2>18:30 Uhr Abendessen</h2>
        <p>Beim Abendessen erwartet uns ein vielfältiges Buffet mit leckerem Fisch, Fleisch und vegetarischen Optionen.</p>
      </div>
      <div v-show="showDiv9" class="timeline fade"><div :style="party"></div></div>
      <div v-show="showDiv9" class="dot fade" :style="partydot"></div>
      <div v-show="showDiv10" class="fade">
        <h2>21:00 Uhr Party</h2>
        <p>Spaß, Spaß, Spaß!</p>
      </div>
      <div v-show="showDiv11" class="timeline fade"><div :style="kaffee"></div></div>
      <div v-show="showDiv11" class="dot fade" :style="kaffeedot"></div>
      <div v-show="showDiv12" class="fade">
        <h2>23:00 Uhr Kaffe & Kuchen</h2>
        <p>Akkus auftanken! Wir holen uns eine große Tasse Kaffee und ein Stück Kuchen, damit wir lange durchhalten. </p>
      </div>
      <div v-show="showDiv11" class="timeline fade"><div :style="last"></div></div>
    </transition-group>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TagesplanSite',
  components: {
  },
  props: {
    msg: String
  },
  created() {
     
  },
  mounted(){
    this.settimeline();
    setInterval(() => {
      this.settimeline();
    }, 60000); // Aktualisiert jede Minute
  },  
  data() {
    return {
      trauung:{
        backgroundColor: '#f9dcdd',
        width:'4px',
        height: '0px'
      },
      trauungdot:{
        backgroundColor: '',
      },
      sekt:{
        backgroundColor: '#f9dcdd',
        width:'4px',
        height: '0px'
      },
      sektdot:{
        backgroundColor: '',
      },
      apero:{
        backgroundColor: '#f9dcdd',
        width:'4px',
        height: '0px'
      },
      aperodot:{
        backgroundColor: '',
      },
      essen:{
        backgroundColor: '#f9dcdd',
        width:'4px',
        height: '0px'
      },
      essendot:{
        backgroundColor: '',
      },
      party:{
        backgroundColor: '#f9dcdd',
        width:'4px',
        height: '0px'
      },
      partydot:{
        backgroundColor: '',
      },
      kaffee:{
        backgroundColor: '#f9dcdd',
        width:'4px',
        height: '0px'
      },
      kaffeedot:{
        backgroundColor: '',
      },
      last:{
        backgroundColor: '#f9dcdd',
        width:'4px',
        height: '0px'
      },
      showDiv1: true,
      showDiv2: true,
      showDiv3: true,
      showDiv4: true,
      showDiv5: true,
      showDiv6: true,
      showDiv7: true,
      showDiv8: true,
      showDiv9: true,
      showDiv10: true,
      showDiv11: true,
      showDiv12: true
    }
  },
  methods: { 
    settimeline() {
        var now = new Date();
        console.log("Monta " + now.getMonth());
        if(now.getDate() === 10 && now.getMonth() === 4 && now.getFullYear() === 2025 ){
        
        var T=15*60;
        var s = 0*60;
        var tm = this.trim((now.getHours() * 60) + now.getMinutes(),T);
        var percentage = ((tm-s) / (T-s)) * 50;
        this.trauung.height = Math.round(percentage) + "px";
        if(tm >= (T)){
          this.trauungdot.backgroundColor = '#f9dcdd';
        }

        var T=15*60 + 45;
        var s = 15*60;
        var tm = this.trim((now.getHours() * 60) + now.getMinutes(),T);
        var percentage = ((tm-s) / (T-s)) * 50;
        this.sekt.height = Math.round(percentage) + "px";
        if(tm >= (T)){
          this.sektdot.backgroundColor = '#f9dcdd';
        }

        var T=17*60;
        var s = 15*60+45;
        var tm = this.trim((now.getHours() * 60) + now.getMinutes(),T);
        var percentage = ((tm-s) / (T-s)) * 50;
        this.apero.height = Math.round(percentage) + "px";
        if(tm >= (T)){
          this.aperodot.backgroundColor = '#f9dcdd';
        }

        var T=18*60+30;
        var s = 17*60;
        var tm = this.trim((now.getHours() * 60) + now.getMinutes(),T);
        var percentage = ((tm-s) / (T-s)) * 50;
        this.essen.height = Math.round(percentage) + "px";
        if(tm >= (T)){
          this.essendot.backgroundColor = '#f9dcdd';
        }

        var T=21*60;
        var s = 18*60+30;
        var tm = this.trim((now.getHours() * 60) + now.getMinutes(),T);
        var percentage = ((tm-s) / (T-s)) * 50;
        this.party.height = Math.round(percentage) + "px";
        if(tm >= (T)){
          this.partydot.backgroundColor = '#f9dcdd';
        }

        var T=23*60;
        var s = 21*60;
        var tm = this.trim((now.getHours() * 60) + now.getMinutes(),T);
        var percentage = ((tm-s) / (T-s)) * 50;
        this.kaffee.height = Math.round(percentage) + "px";
        if(tm >= (T)){
          this.kaffeedot.backgroundColor = '#f9dcdd';
        }

        var T=27*60;
        var s = 23*60;
        var tm = this.trim((now.getHours() * 60) + now.getMinutes(),T);
        var percentage = ((tm-s) / (T-s)) * 50;
        this.last.height = Math.round(percentage) + "px";
      }else if(now.getDate() === 11 && now.getMonth() === 4 && now.getFullYear() === 2025 ){
        var T=27*60;
        var s = 23*60;
        var tm = this.trim(((24 + now.getHours()) * 60) + now.getMinutes(),T);
        var percentage = ((tm-s) / (T-s)) * 50;
        this.last.height = Math.round(percentage) + "px";
      }
    },
    trim(number, limit) {
      if (number > (limit)) {
        return (limit);
      }
      return number;
      }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.wrap{
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 80vw;
}
p{
  font-family: "Poppins", serif;
  font-weight: 500;
  font-style: normal;
}
h1{
  font-size: 30pt;
}
.locations{
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
}

.timeline{
  height: 50px;
  width: 4px;
  background-color: #3a6090;
  display: flex;
  justify-self: center;
  align-self: center;
}
.dot{
  width: 10px;
  height: 10px;
  margin-top: -1px;
  background-color: #3a6090;
  display: flex;
  justify-self: center;
  align-self: center;
  border-radius: 50%;
}

#t{
  width: 4px;
  height: 10px;
  background: #f9dcdd;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 1;
}
</style>
